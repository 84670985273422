import React from 'react';
import Layout from '../../Components/Layout/Layout';
import './Series.scss';
import { graphql } from 'gatsby';
import { PortableText } from '@portabletext/react';

export const query = graphql`
  query seriesQuery($id: String!) {
    allSanitySeries(filter: { id: { eq: $id } }) {
      nodes {
        title
        _rawSummary
        summary {
          children {
            text
          }
        }
        _rawContent
        banner {
          alt
          image {
            asset {
              url
            }
          }
        }
      }
    }
  }
`;
/**
 * @namespace Series
 * @function Series
 * @author Trevor Cash
 * @since 11/06/21
 * @version 1.0.0
 * @component
 */
export default function Series(props) {
  const series = props.data?.allSanitySeries?.nodes?.[0];
  return (
    <Layout
      className='Series-Container'
      title={series?.title}
      description={series?.summary
        ?.map((item) => item.children[0].text)
        .join(' ')}
    >
      {series?.title && <h1>{series?.title}</h1>}
      {series?.banner?.image?.asset?.url && (
        <img
          src={series?.banner?.image?.asset?.url}
          alt={series?.banner?.alt}
          loading='lazy'
        />
      )}
      {series?._rawSummary && <PortableText value={series?._rawSummary} />}
      {/* {series?.content?.map((item) => {
        switch (item._type) {
          case 'heading':
            return <h2>{item.value}</h2>;
          case 'subheading':
            return <h3>{item.value}</h3>;
          case 'paragraph':
            return <p>{item.value}</p>;
          default:
            break;
        }
      })} */}
      <PortableText value={series._rawContent} />
    </Layout>
  );
}
